body,
html {
  margin: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: -webkit-fill-available;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  width: 100vw;
  min-height: var(--vh);
  min-width: 1400px;
  background: #f3f7fa;
}

.app-layout {
  height: 100%;
}
#root {
  background: #f3f7fa;
  min-height: var(--vh);
}

.no-scroll {
  overflow: hidden;
}

.fs-12 {
  font-size: 12px;
}

@supports (bottom: env(safe-area-inset-bottom)) {
  body,
  .safe-area {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
}
