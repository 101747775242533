@primary: var(--mainTheme);
@primary-base-white: #fff;
@primary-hover: #6993ff;
@primary-active: #2a4fd4;
@actionColor: #ff5501;
@main-color: #1f1f1f;
@content-color: #293d66;
@text-color: #4d5e80;
@main-text-color: #1f2e59;
@label-color: #4d5e8c;
@card-radius: 8px;
@primary-light-bg-hover: #E3EEFF;
@primary-light-hover: #F0F6FF;
@primary-border: #BAD2FF;
@primary-border-hover: #91B4FF;
@primary-backup1: #1A35AD;
@primary-backup2: #0E2087;
@primary-backup3: #091361;
@primary-text-color1: #02091F;
@primary-text-color2: #1F2C52;
@primary-text-color3: #6B789E;
@primary-text-color4: #8F99B8;
@primary-border-color1: #D3DAF0;
@primary-border-color2: #EDF0F9;
@primary-background-color: #F2F5FC;
@primary-background-color1: #E3E9FC;
@primary-background-color2: #F0F3FE;
@primary-background-color3: #F7F9FF;
@primary-up-success-color1: #1FBFA2;
@primary-up-success-color2: #8FDFD1;
@primary-up-success-color3: #E9F9F6;
@primary-down-error-color1: #FF4D4D;
@primary-down-error-color2: #FFA6A6;
@primary-down-error-color3: #FFEDED;
@primary-waring-color1: #FFB525;
@primary-waring-color2: #FFDA92;
@primary-waring-color3: #FFF8E9;
@primary-message-color1: #3F6FFb;
@primary-message-color2: #BAD2FF;
@primary-message-color3: #F0F6FF;
@primary-icon-tag-color: #72C140;
@primary-message-border-color: #B8E09F;
@primary-help-color: #F1F9EC;






.rtl-less(@ltr-property, @rtl-property, @value) {
  [dir='ltr'] & {
    @{ltr-property}: @value;
  }

  [dir='rtl'] & {
    @{rtl-property}: @value;
  }
}
.load-wrap {
  width: 100%;
  height: var(--vh);
  display: flex;
  align-items: center;
  justify-content: center;
}
.load-wrap-middle {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.load-wrap-small {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
